import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ic_options from "../assets/images/Trasnvehicle/ic_options.svg";
import dropdown_arrow_blue from "../assets/images/dropdown_arrow_blue.svg";
import { Modal } from "react-bootstrap";
import ic_vehicle from "../assets/images/AddVehicles/ic_vehicle.svg";
import circle_cross from "../assets/images/circle_cross.svg";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../context/user/AppContext";
import {
  PostCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";
import Select from "react-select";
const OfferVehicles = () => {
  const { mainClass, customerData, setVehicleInformation, vehicleInformation } = useContext(AppContext);
  const navigate = useNavigate();
  const data = useLocation().state;
  const [Load, setLoad] = useState({ ...data });
  const [loadCategoryType, setLoadCategoryType] = useState("");
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [container, setContainer] = useState([]);
  const [trailerContainer, setTrailerContainer] = useState([]);
  const [containerNames, setContainerNames] = useState([{}]);
  const [selectedPlateno, setSelectedplateno] = useState("");
  const [vehicleDetails, setVehicleDetails] = useState({
    truck_id: "",
    trailer_id: "",
    driver_id: "",
    bid_id: (Load?.bidres),
    trip_id: Load?.trip_id,
  });

  $(document).ready(function () {
    $(".datalist").DataTable();
  });
  const [selectVehicle, setSelectVehicle] = useState({ vehicle_images: [] });
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [vehicleList, setVehicleList] = useState([]);
  const [axleType, setAxleType] = useState("");
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [driverList, setDriverList] = useState([]);
  const [trailerList, setTrailerList] = useState([]);
  const [vehicleNameValue, setVehicleNameValue] = useState("");
  const [vehicle_name_id, setVehicle_name_id] = useState("");
  const [selectedtrailer, setSelectedtrailer] = useState({
    vehicle_images: [],
  });
  const [checkTrailer, setCheckTrailer] = useState([]);
  const [plateNoList, setPlateNoList] = useState([]);
  const [addedVehicleList, setAddedVehicleList] = useState([]);
  const handleOfferVehicle = () => {
    setShow(true);
    setSelectedDriver([]);
    setSelectVehicle({});
    setSelectedtrailer({});
    setCheckTrailer([]);
    setVehicleDetails({ ...vehicleDetails, driver_id: "" });
    getPowerPlateNoList();
  };
  useEffect(() => {
    if (Load?.auction_details) {
      //getBidList();
    }
    let vehicle_list = [];
    setSelectVehicle(selectVehicle);
    getAddVehicleList();
    getDriverList();
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.TRUCK_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        const data = res?.json?.vehicle_types;
        const filteredTruckList = data.filter((truck) => {
          return !addedVehicleList.some(
            (vehicle) => {if (vehicle.vehicle_id == truck.vehicle_name_id) {
                vehicle_list.push(truck.vehicle_name);
                return true;
            }}
          );
        });
        setVehicleList(filteredTruckList);
      })
      .catch((err) => console.log(err));

    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.TRAILER_LIST,
      JSON.stringify({ ...customerData, vehicle_type: selectedVehicleType,
        cargo_type: Load?.cargo_type, container_type: Load?.container_type, 
        container_quantity: Load?.trip_container_quantity, 
        axle_type: axleType})
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        const data = res?.json?.vehicle_list;
        const filteredTrailerList = data.filter((trailer) => {
          return !addedVehicleList.some(
            (vehicle) => vehicle.trailer_id == trailer.vehicle_id
          );
        });
        setTrailerList(filteredTrailerList);
      })
      .catch((err) => console.log(err));
  }, [show, selectedVehicleType, axleType]);

  const setCurrentPlateNo = (e) => {
    let vehicle = plateNoList.filter(
      (vehicle) => vehicle.vehicle_id == e?.target?.value
    )[0];
    setSelectVehicle({
      ...vehicle,
      load_reference_no: Load && Load.load_reference_no,
    });
    let cont_array = [];
    cont_array.push(vehicle?.container_type_value_id);
    setContainer(cont_array)
    setVehicleDetails({
      ...vehicleDetails,
      truck_id: vehicle?.vehicle_id,
      //vehicle_axle_id: vehicle?.vehicle_axle_id,
      // trailer_id: trailer?.vehicle_id,
    });
    if (selectVehicle?.vehicle_type === "Truck Trailer") {
      setVehicleDetails({ ...vehicleDetails, trailer_id: null });
    }
    setAxleType(vehicle.vehicle_axle_type);
  };
  const setCurrentVehicle = (e) => {
    setSelectVehicle({});
    setSelectedVehicleType(e?.target?.value);
    setVehicleDetails({ ...vehicleDetails, trailer_id: "", truck_id: "" });
    const selectedId = e.target.options[e.target.selectedIndex].id;
    let vehicle = vehicleList.filter(
      (vehicle) => vehicle.vehicle_name_id == selectedId,
      setVehicle_name_id(selectedId)
    );
  };
  const setCurrenttrailer = (e) => {
    //console.log("idd " + e.target.value);
    let trailer_id_val = e.target.value;
    
    let trailer = trailerList.filter(
      (vehicle) => { 
        if (vehicle.vehicle_id == e.target.value) {  
          return true;
      }}
    )[0];
    setCheckTrailer([{ ...trailer }]);
    setSelectedtrailer({
      ...trailer,
      load_reference_no: Load && Load.load_reference_no,
    });
    let cont_array = [];
    if (Load?.cargo_type === "Container"){
      cont_array.push(trailer?.container_type_value_id[0]);
    }
    
    if (Load?.cargo_type === "Container" && trailer?.container_type_value_id[1]) {
      cont_array.push(trailer?.container_type_value_id[1]);
    }
    
    setTrailerContainer(cont_array);
    setVehicleDetails({
      ...vehicleDetails,
      // truck_id: vehicle?.vehicle_id,
      trailer_id: trailer_id_val,
    });
  };
  const setCurrentDriver = (e) => {
    let driver = driverList.filter(
      (driver) => driver.driver_id == e.target.value
    )[0];
    setSelectedDriver(driver);
    setVehicleDetails({ ...vehicleDetails, driver_id: driver.driver_id });
  };

  // const getBidList = () => {
  //   // setLoading(true);
  //   postWithAuthCallWithErrorResponse(
  //     ApiConfig.BIDLIST,
  //     JSON.stringify({ ...customerData, load_id: Load?.trip_id })
  //   )
  //     .then((res) => {
  //       if (res.json.result) {
  //         res.json.bid_list.map((item) => {
  //           //setVehicleDetails({ ...vehicleDetails, bid_id: item.bid_id });
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Ex :: ", err);
  //     });
  // };
  const getPowerPlateNoList = () => {
    // setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.POWER_PLATE_NO,
      JSON.stringify({ ...customerData, vehicle_name_id: vehicle_name_id, 
          cargo_type: Load?.cargo_type, container_type: Load?.container_type, 
          container_quantity: Load?.trip_container_quantity})
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          const data = res?.json?.vehicle_list;
          const filteredPlateNumberList = data.filter((plate) => {
            return !addedVehicleList.some(
              (vehicle) => vehicle.vehicle_id == plate.vehicle_id
            );
          });
          // const filteredArray = dataArray.filter((item) => item.plate_number !== plateNumberToRemove);
          setPlateNoList(filteredPlateNumberList);
          setVehicleDetails({
            ...vehicleDetails,
            truck_id: filteredPlateNumberList[0]?.vehicle_id,
          });
          filteredPlateNumberList?.map(e=>           
            {e?.vehicle_container_id.split(",")[3].replace("{", "").replace("}", "")}
          )
          
          //containerNames
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };
  const getDriverList = () => {
    // setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.ACTIVE_DRIVER_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          const data = res?.json?.driver_list;
          const filteredDriverList = data.filter((driver) => {
            return !addedVehicleList.some(
              (vehicle) => vehicle.driver_id === driver.driver_id
            );
          });
          setDriverList(filteredDriverList);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };
  const getAddVehicleList = async () => {
    $("#add_vehicle").removeAttr("disabled");
    // setLoading(true);
    if (Load?.auction_details) {
      await postWithAuthCallWithErrorResponse(
        ApiConfig.ONLINE_LISTVEHICLEOFFER,
        JSON.stringify({
          ...customerData,
          reference_no: Load.load_reference_no,
          bid_id: vehicleDetails?.bid_id,
        })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          // setLoading(false);
          if (res.json.result) {
            setAddedVehicleList(res.json.vehicle_list);
          }
        })
        .catch((err) => {
          console.log("Ex :: ", err);
        });
    } else {
      postWithAuthCallWithErrorResponse(
        ApiConfig.LISTVEHICLEOFFER,
        JSON.stringify({
          ...customerData,
          reference_no: Load.load_reference_no,
        })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          // setLoading(false);
          if (res.json.result) {
            setAddedVehicleList(res.json.vehicle_list);
            setLoadCategoryType(res.json.load_category);
          }
        })
        .catch((err) => {
          console.log("Ex :: ", err);
        });
    }
  };
  
  const addVehicleOffer = (e) => {
    $("#add_vehicle").attr("disabled", "disabled");
    if (Load?.auction_details) {
      let online_details = JSON.stringify({
        customer_id: customerData.customer_id,
        api_key: customerData.api_key,
        user_id: customerData.user_id,
        truck_id: vehicleDetails?.truck_id,
        trailer_id: Number(vehicleDetails?.trailer_id)
          ? Number(vehicleDetails?.trailer_id)
          : "",
        driver_id: vehicleDetails?.driver_id,
        reference_no: Load?.load_reference_no,
        bid_id: vehicleDetails?.bid_id,
        vehicle_container_id: container,
        trailer_vehicle_container_id: trailerContainer,
        cargo_type: Load?.cargo_type,        
      });
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.ADD_ONLINE_VEHICLEOFFER,
        online_details
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          swal(res.json.message);
          setShow(false);
          getAddVehicleList();
        })
        .catch((err) => console.log(err));
    } else {
      
      let details = JSON.stringify({
        customer_id: customerData.customer_id,
        api_key: customerData.api_key,
        user_id: customerData.user_id,
        truck_id: vehicleDetails?.truck_id,
        trailer_id: Number(vehicleDetails?.trailer_id)
          ? Number(vehicleDetails?.trailer_id)
          : "",
        driver_id: vehicleDetails?.driver_id,
        reference_no: Load?.load_reference_no,
        vehicle_container_id: container,
        trailer_vehicle_container_id: trailerContainer,
        cargo_type: Load?.cargo_type,
      });
            if (vehicleDetails?.driver_id) {
        setVehicleInformation({
          "customer_id": customerData.customer_id,
          "api_key": customerData.api_key,
          "user_id": customerData.user_id,
          "truck_id": vehicleDetails?.truck_id,
          "trailer_id": Number(vehicleDetails?.trailer_id)
            ? Number(vehicleDetails?.trailer_id)
            : "",
          "driver_id": vehicleDetails?.driver_id,
          "reference_no": Load?.load_reference_no,});
        postMultipartWithAuthCallWithErrorResponse(
          ApiConfig.ADDVEHICLEOFFER,
          details,
        )
          .then((res) => {
            if (res.json.message === 
              "Invalid user authentication,Please try to relogin with exact credentials.") {
                localStorage.clear();
                window.open("/login", "_self");
            }
            swal(res.json.message);
            setShow(false);
            getAddVehicleList();
                     })
          .catch((err) => console.log(err));
      } else {
        swal("Driver for these operation is not avilable ");
      }
    }
  };
  const sendVehicleOffer = () => {
    $("#send_offer").attr("disabled", "disabled");
    // let details = JSON.stringify({
    //   customer_id: customerData.customer_id,
    //   api_key: customerData.api_key,
    //   user_id: customerData.user_id,
    //   reference_no: Load?.load_reference_no,
    // });
    let details = JSON.stringify({
      customer_id: customerData.customer_id,
      api_key: customerData.api_key,
      user_id: customerData.user_id,
      truck_id: vehicleDetails?.truck_id,
      trailer_id: Number(vehicleDetails?.trailer_id)
        ? Number(vehicleDetails?.trailer_id)
        : "",
      driver_id: vehicleDetails?.driver_id,
      reference_no: Load?.load_reference_no,
      bid_id: vehicleDetails?.bid_id,
    });
    if (Load?.auction_details) {
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.ONLINE_SENDVEHICLEOFFER,
        details
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          $("#send_offer").removeAttr("disabled");
          swal(res.json.message);
          navigate("/OfferedVehiclesOnline");
          
        })
        .catch((err) => console.log(err));
    } else {
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.SENDVEHICLEOFFER,
        details
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          swal(res.json.message);
          navigate("/OfferedVehicles");
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getDropDownList();
  }, []);
  const getDropDownList = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_DROPDOWNLIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
        let containers = []; 
        res.json.vehicle_container.map(e=> {
          containers.push({label: e.container_type_name, value: e.container_type_id});
        })
        let length = containers.length;
        res.json.vehicle_container.filter(f=> (f.container_type_id%2 != 0 && f.container_type_id != 9)).map(e=> {
          containers.push({label: e.container_type_name, value: (e.container_type_id + length + 2)});
        })
        setContainerNames(containers);

      }
    });
  };
  
  const deleteVehicleOffer = (id, bidId, vehicle_id, trailer_id, trip_id) => {
    if (!Load?.auction_details) {
      postWithAuthCallWithErrorResponse(
        ApiConfig.DELETE_VEHICLE_OFFER,
        JSON.stringify({
          ...customerData,
          //trip_bid_id: bidId,
          trip_vehicle_id: id,
          vehicle_id: vehicle_id,
          trailer_id: trailer_id,
          trip_id: trip_id
        })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          swal(res.json.message);
          navigate("/OfferGoodsDirect/");
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (Load?.auction_details) {
      postWithAuthCallWithErrorResponse(
        ApiConfig.DELETE_BID_VEHICLE_OFFER,
        JSON.stringify({
          ...customerData,
          bid_id: bidId,
          trip_vehicle_id: id,
          vehicle_id: vehicle_id,
          trailer_id: trailer_id,
          trip_id: trip_id
        })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          swal(res.json.message);
          navigate("/VehicleRequestOnlineAuction/");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  

  useEffect(() => {
    if (vehicle_name_id) {
      getPowerPlateNoList();
    }
  }, [vehicle_name_id]);

  useEffect(() => {
  }, [axleType])
  
  return (
    <>
      <main className={"main-offerVehicles " + mainClass}>
        <div className="btn-offer-vehicle-main text-end mb-4">
          <button
            className="btn-offer-vehicle "
            onClick={() => handleOfferVehicle()}
          >
            Offer Vehicles
          </button>
        </div>

        <div
          className=""
          style={{
            width: "100",
            overflow: "auto",
            height: "50vh",
            marginBottom: "60px",
          }}
        >
          <table className="  ">
            <thead>
              <tr>
                <th>
                  <label>S.No</label>
                </th>
                <th>
                  <label>Reference Number</label>
                </th>

                {/* <th>
                  <label>Vehicle Type</label>
                </th> */}
                <th>
                  <label>Model</label>
                </th>
                <th>
                  <label>Power Plate No. & Type</label>
                </th>
                <th>
                  <label>Trailer Plate No. & Type</label>
                </th>
                <th>
                  <label>Driver Name</label>
                </th>
                <th>
                  <label></label>
                </th>
              </tr>
            </thead>
            <tbody>
              {addedVehicleList.map((vehicle, index) => {
                return (
                  <tr className="Rowbgcolor " key={index + "vehicle"}>
                    <td>{index + 1}</td>
                    <td id="Owner">{Load.load_reference_no} </td>
                    {/* <td id="Vehicle">{vehicle.owner_name}</td> */}
                    {/* <td id="Model">{vehicle.vehicle_type}</td> */}
                    <td id="Plate">{vehicle.vehicle_model_no}</td>
                    <td id="DriverNAM">
                      {vehicle.plate_number}
                      {"-"}
                      {vehicle.vehicle_type}
                    </td>
                    <td id="DriverNAM">
                      {vehicle.trailer_plate_no}
                      {"-"}
                      {vehicle.trailer_vehicle_type}
                    </td>
                    <td id="Nationality">{vehicle.driver_name}</td>
                    <td>
                      <div className="option-dropdown dropdown p-2">
                        <a
                          href=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <Link
                              to={
                                "/VehicleDriverDetailsHeader/" +
                                vehicle.vehicle_id
                              }
                              state={{
                                id: vehicle.vehicle_id,
                                transporter_id: vehicle.owner_id,
                                driver_details: vehicle.driver_details,
                              }}
                              className="text-decoration-none"
                            >
                              <button className="dropdown-item" type="button">
                                View Details
                              </button>
                            </Link>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => {
                                deleteVehicleOffer(
                                  vehicle?.trip_vehicle_id,
                                  vehicleDetails?.bid_id,
                                  vehicle?.vehicle_id,
                                  vehicle?.trailer_id,
                                  vehicleDetails?.trip_id,
                                );
                              }}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="fix-offer-vehicles" style={{ overflow: "auto" }}>
          <div className="row p-4  mb-4" style={{ minWidth: "1250px" }}>
            <div className="col-2 ">
              <label htmlFor="">Offer Load Reference No.</label>
              <p className="data">{Load && Load.load_reference_no}</p>
            </div>
            {/* <div className="col-2 ">
              <label htmlFor="">Availability date of vehicle</label>
              <p className="data">{Load && Load.vehicle_availability_date}</p>
            </div> */}
            <div className="col-2 ">
              <label htmlFor="">Cargo Type</label>
              <p className="data">{Load && Load.cargo_type}</p>
            </div>
            <div className="col-2 ">
              <label htmlFor="">Quantity</label>
              <p className="data">{Load && Load.quantity}</p>
            </div>
            {/* <div className="col-2 ">
              <label htmlFor="">Number of Trucks</label>
              <p>
                <span className="data m-0">{Load && Load.truck_count}</span>{" "}
              </p>
            </div> */}
          </div>
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="#">
                <button type="cancel" className="cancel-btn">
                  Cancel
                </button>
              </a>
              <Link to="#" onClick={sendVehicleOffer}>
                <button id="send_offer" type="submit" className="continue-btn">
                  Send Offer
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        className="offer-vehicle-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="card-heading">Offer Vehicle</h5>
          </Modal.Title>
        </Modal.Header>
        <hr />

        <Modal.Body>
          <section className="offer-vehicles ">
            <div className="row " style={{ marginBottom: "70px" }}>
              {selectVehicle?.vehicle_image && (
                <div className="col-lg-3 col-md-6 ">
                  <div className="top-item mt-2">
                    <label className="" style={{ color: "#66739B" }}>
                      Vehicle Images
                    </label>
                  </div>
                  <div className="uploaded_items d-flex mt-4">
                    {selectVehicle.vehicle_images &&
                      selectVehicle.vehicle_images.map((img) => {
                        return (
                          <div className="item_list">
                            <img
                              className="vehicle_img"
                              width="200px"
                              height={200}
                              src={
                                ApiConfig.BASE_URL_FOR_IMAGES +
                                img.vehicle_image_url
                              }
                              alt="No image "
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              <div className="col-lg-9 col-md-6">
                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Reference Number
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={Load && Load.load_reference_no}
                      />
                    </div>
                  </div>
                  
                  <div className="col-lg-4">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Vehicle Type</label>
                      <select
                        className="form-select"
                        onChange={(e) => {
                          setCurrentVehicle(e);                              
                          {setVehicleNameValue(e.target.value)}

                        }}
                      >
                        <option>Select Vehicle</option>
                        {vehicleList?.map((vehicle) => {
                          return (
                            <option
                              id={vehicle.vehicle_name_id}
                              value={vehicle.vehicle_name_value}
                            >
                              {vehicle.vehicle_name_value} {' '} 
                                {(vehicle.vehicle_name_id !== 6 && vehicle.vehicle_name_id !== 3)? 
                                  " Power" : ""}
                              
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Power Plate Number
                      </label>
                      <select
                        type="search"
                        className="form-select"
                        id="exampleInputEmail1"
                        onChange={(e) => {
                          setCurrentPlateNo(e);
                          //setContainer(selectVehicle.container_type_value_id);
                        }}
                        aria-describedby="emailHelp"
                      >

                        <option>Select Vehicle</option>
                        {plateNoList?.filter(cargo=> 
                          cargo.cargo_type_value === Load?.cargo_type).map((vehicle) => {
                          return (
                            <option value={vehicle.vehicle_id}>
                              {" "}
                              {/* {vehicle.plate_number}
                              {"-"} */}
                              {vehicle.plate_number}
                              
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {(Load?.cargo_type && 
                    (selectedVehicleType !== "High Bed" && selectedVehicleType !== "Low Bed"
                      && selectedVehicleType !== "Car Career Power"
                    )) ?
                  <div className="col-lg-12 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                       Required Cargo Type
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={Load?.cargo_type}
                      />
                    </div>
                  </div> : <></>
                  } 
                  {/* {vehicleNameValue == "Power"  
                  && Load?.cargo_type=="Container" && selectVehicle.vehicle_id ?
                    <div className="form-group mt-3 col-md-12">
                           <label htmlFor="exampleInputEmail1">
                             Container
                           </label>
                           <Select
                           value={container}
                           //className="form-select"
                           isMulti
                           options={containerNames.filter(e => {
                            return e.value === 1 || e.value === 3 || e.value === 5 || e.value === 7
                           })}
                            // .filter(e=> e.value === 2 
                            //   || e.value===4 || e.value===6 || e.value===8
                              
                            
                           //aria-label="Default select example"
                           onChange={(container) => {
                                if (container.length === 0 && !container[0]?.value) {
                                  setContainer([])
                                }
                                
                                if (container.length == 1 && ((container[container.length-1]?.value === 1 || 
                                  container[container.length-1]?.value===3
                                    || container[container.length-1]?.value === 5 
                                    || container[container.length-1]?.value ===7)
                                     )){
                                        setContainer(container);
                                      
                                }
                              }}
                            >
                         </Select>
                         </div>:<></>} */}
                         {/* {vehicleNameValue == "Truck Trailer"  
                  && Load?.cargo_type=="Container" && selectVehicle.vehicle_id ?
                    <div className="form-group mt-3 col-md-12">
                           <label htmlFor="exampleInputEmail1">
                             Container
                           </label>
                           <Select
                           value={container}
                           //className="form-select"
                           isMulti
                           options={containerNames.filter(e => {
                            return e.value === 1 || e.value === 3 || e.value === 5 || e.value === 7
                           })}
                            // .filter(e=> e.value === 2 
                            //   || e.value===4 || e.value===6 || e.value===8
                              
                            
                           //aria-label="Default select example"
                           onChange={(container) => {
                                if (container.length === 0 && !container[0]?.value) {
                                  setContainer([])
                                }
                                
                                if (container.length == 1 && ((container[container.length-1]?.value === 1 || 
                                  container[container.length-1]?.value===3
                                    || container[container.length-1]?.value === 5 
                                    || container[container.length-1]?.value ===7)
                                     )){
                                        setContainer(container);
                                      
                                }
                              }}
                            >
                         </Select>
                         </div>:<></>} */}


                         {(Load?.cargo_type === "Container" && 
                    (selectedVehicleType !== "High Bed" && selectedVehicleType !== "Low Bed"
                      && selectedVehicleType !== "Car Career Power"
                    )
                  ) ?
                         <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail2">
                       Container
                      </label>
                      
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail12"
                        aria-describedby="emailHelp"
                        value={selectVehicle?.container_type_value
                          ? selectVehicle?.container_type_value
                          : ""}
                        
                      />
                    </div>: <></>} 
                  {selectedVehicleType !== "High Bed" ? (
                    <></>
                  ) : 
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Axle Type</label>
                      <input
                        type="text"
                        disabled
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle?.vehicle_axle_type
                            ? selectVehicle?.vehicle_axle_type
                            : ""
                        }
                      />
                    </div>
                  </div>}
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Model</label>
                      <input
                        type="text"
                        disabled
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle?.vehicle_model_no
                            ? selectVehicle?.vehicle_model_no
                            : ""
                        }
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Year of Manufacture
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.year_manufacture
                            ? selectVehicle.year_manufacture
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Chassis Number</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_chassis_no
                            ? selectVehicle.vehicle_chassis_no
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Motor Number</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_motor_no
                            ? selectVehicle.vehicle_motor_no
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Gross Weight</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_gross_weight
                            ? selectVehicle.vehicle_gross_weight
                            : ""
                        }
                      />
                    </div>
                  </div>
                  {(selectedVehicleType === "Power" || selectedVehicleType === "Truck Trailer")? <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Vehicle Load Capacity in Quintals
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_capacity
                            ? selectVehicle.vehicle_capacity
                            : ""
                        }
                      />
                    </div>
                  </div> : <></>}
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Current km</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.vehicle_initial_km
                            ? selectVehicle.vehicle_initial_km
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div>
                    </div>
                  {vehicleNameValue !== "Power" ? (
                    <>
                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Trailer Plate Number
                          </label>
                          <select
                            type="search"
                            className="form-select"
                            id="exampleInputEmail1"
                            onChange={(e) => {
                              setCurrenttrailer(e);
                              //setTrailerContainer(selectedtrailer.container_type_value_id)
                            }}
                            aria-describedby="emailHelp"
                          >
                            <option>Select Vehicle</option>
                            {trailerList?.filter(cargo=> cargo.cargo_type_value 
                            === Load?.cargo_type).map((vehicle) => {
                              return (
                                <option value={vehicle.vehicle_id}>
                                  {vehicle.plate_number}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {Load?.cargo_type &&
                  <div className="col-lg-12 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                       Requried Cargo Type
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={Load?.cargo_type}
                      />
                    </div>
                  </div>
                  
                  } 
              
                {(Load?.cargo_type === "Container") ?
                         <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                      Container
                      </label>
                     
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedtrailer?.container_type_value
                          ? selectedtrailer?.container_type_value[1] ? selectedtrailer?.container_type_value[1] + 
                            ", " + selectedtrailer?.container_type_value[1] : selectedtrailer?.container_type_value[0] 
                          : ""}
                      />
                    </div>: <></>} 
                  
                      {/* <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">Model</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={selectedtrailer?.vehicle_model_no}
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-4">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Vehicle Type
                          </label>
                          <input
                            disabled
                            className="form-control"
                            aria-label="Default select example"
                            value={
                              selectedtrailer?.vehicle_type
                                ? selectedtrailer?.vehicle_type
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Year of Manufacture
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={selectedtrailer?.year_manufacture}
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Chassis Number
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={
                              selectedtrailer?.vehicle_chassis_no
                                ? selectedtrailer?.vehicle_chassis_no
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Motor Number
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={selectedtrailer?.vehicle_motor_no}
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Gross Weight
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={
                              selectedtrailer?.vehicle_gross_weight
                                ? selectedtrailer?.vehicle_gross_weight
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Vehicle Load Capacity {selectedVehicleType === "Car Career Power" ? 
                              " Vehicle Units" : " Quintals"}
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={
                              selectedtrailer?.vehicle_capacity
                                ? selectedtrailer?.vehicle_capacity
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {/* {selectedtrailer?.vehicle_type === '4' ?

                      <div className="col-lg-4 ">
                        <div className="form-group mt-2">
                          <label htmlFor="exampleInputEmail1">Current km</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={
                              selectedtrailer?.vehicle_initial_km
                                ? selectedtrailer?.vehicle_initial_km
                                : ""
                            }
                          />
                        </div>
                      </div>: <></>} */}
                    </>
                  ) : <></>}
                  <div className="col-lg-6 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Driver and Licence Number
                      </label>
                      <select
                        type="search"
                        className="form-select"
                        id="exampleInputEmail1"
                        onChange={(e) => {
                          setCurrentDriver(e);
                        }}
                        aria-describedby="emailHelp"
                      >
                        <option value="" selected>
                          Select Driver
                        </option>

                        {driverList &&
                          driverList.length &&
                          driverList.map((driver) => {
                            return (
                              <option value={driver.driver_id}>
                                {" "}
                                {driver.driver_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Driver Driving License
                      </label>
                      <input
                        disabled
                        type="search"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedDriver && selectedDriver.licence_number}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="#">
                <button
                  type="submit"
                  className="cancel-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                >
                  Cancel
                </button>
              </a>
              <Link to="#">
                <button
                  id="add_vehicle"
                  type="submit"
                  className="continue-btn"
                  onClick={(e)=> {addVehicleOffer(e)}}
                  // disabled={vehicleDetails?.driver_id ? false : true}
                >
                  Add Vehicle
                </button>
              </Link>
              {/* <div className="login-btn">
                <button
                  type="submit"
                  
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}> Continue</span>
                </button>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OfferVehicles;
