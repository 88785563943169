import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ApiConfig from "../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import { AppContext } from "../context/user/AppContext";
import Loader from "../sharedComponent/Loader";
const ViewOrderOfferedVehicles = () => {
  const data = useLocation().state;
  const [order, setOrder] = useState({ ...data });
  const { mainClass, customerData } = useContext(AppContext);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.VIEW_ORDER_OFFERED_VEHICLES_DETAILS,
      JSON.stringify({ load_id: order.trip_id, ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          //setOrder(res.json.load_details);
          setTotalPrice(res.json.total_price);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <main className={"View-Order-main " + mainClass} id="cxMain">
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <section className="viewOrder-body common-body mt-4">
          <div className="row">
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Reference Number (Offer Vehicle)</label>
              <p className="data">{order && order.trip_id}</p>
            </div>
            
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Company Name (Shipper)</label>
              <p className="data">{order && order.shipper}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Cargo Type</label>
              <p className="data">{order && order.cargo_type}</p>
            </div>
            {order.container_type_name && <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Container Type</label>
              <p className="data">{order && order.container_type_name ? order.container_type_name : "No Container"}</p>
            </div>}
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Remaining Quantity</label>
              <p className="data">{order && order.cargo_type === "Container" ? 
                 order.trip_pending_quantity === "" ? order.quantity : order.trip_pending_quantity + " Quintals" : 
                    order.cargo_type === "Vehicle" ? order.trip_pending_quantity === "" ? order.quantity : order.trip_pending_quantity + " Vehicles" : 
                      (order.cargo_type==="Bulk" || order.cargo_type === "Break bulk") ?
                      order.trip_pending_quantity === "" ? order.quantity : order.trip_pending_quantity + " Quintals" : " - "}</p>
            </div>
            
            {/* <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Exp. Arrival Time At The Destination</label>
              <p className="data">{order && order.arrival_time}</p>
            </div> */}
            {/* <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Driver Name</label>
              <p className="data">
                {order &&
                  order.vehicles &&
                  order.vehicles
                    .map((vehicle) => vehicle.driver_name)
                    .join(",")}
              </p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Driver Cell Phone</label>
              <p className="data">
                {order &&
                  order.vehicles &&
                  order.vehicles
                    .map((vehicle) => vehicle.driver_phone_no)
                    .join(",")}
              </p>
            </div> */}
          </div>
          <br />
          <span className="confirm-detail-heading my-5 ">
            Transporter Details
          </span>

          <div style={{ width: "100%", overflow: "auto" }}>
            <table className=" " style={{ minWidth: "700px" }}>
              <thead>
                <tr>
                  <td>
                    <label htmlFor="">Transporter’s Name</label>
                  </td>
                  <td>
                    <label htmlFor="">Loading Truck Plate Number</label>
                  </td>
                  <td>
                    <label htmlFor="">Driver Name</label>
                  </td>
                  <td>
                    <label htmlFor="">Driver Cell Phone</label>
                  </td>
                  <td>
                    <label htmlFor="">Trip Status</label>
                  </td>
                </tr>
              </thead>
              <tbody>
                {
                  order?.vehicles?.map((item, index) => {
                    return (
                      <tr className="t-row-confirm mb-3" key={"item" + index}>
                        <td>{item.transporter}</td>
                        <td>
                          <p>{item.plate_no}</p>{" "}
                        </td>
                        <td>
                          <p>{item.driver_name}</p>{" "}
                        </td>
                        <td>
                          <p>{item.driver_phone_no}</p>{" "}
                        </td>
                        <td>
                          <p>{item.trip_vehicle_status}</p>{" "}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </section>
      )}
    </main>
  );
};

export default ViewOrderOfferedVehicles;
