import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import star_highlighted from "../../assets/images/vehiclesDetails/star_highlighted.svg";
import star_grey from "../../assets/images/vehiclesDetails/ic_star_grey.svg";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import user_1 from "../../assets/images/Dashboard/user_2.svg";
import { Modal, Button } from "react-bootstrap";
import eyes from "../../assets/images/eyes.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import { AppContext } from "../../context/user/AppContext";
const ViewDetails = () => {
  const freight = useLocation().state;
  const [freightetails, setFrightDetails] = useState({ ...freight });
  const [vehicleList, setVehicleList] = useState({});
  const [vehicleInfo, setVehicleInfo] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getFrightDetails();
  };
  const [show, setShow] = useState(false);

  const { customerData, mainClass } = useContext(AppContext);
  useEffect(() => {
    getFrightDetails();
  }, []);
  const getFrightDetails = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_LIST_BY_LOADID,
      JSON.stringify({ ...customerData, load_id: freightetails.trip_id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      console.log(res);
      if (res.json.result) {
        setVehicleList(res.json.vehicle_list);
        vehicleList.map((item) => {
          setVehicleInfo(item);
        });
      }
    });
  };
  return (
    <div>
      {/* <Sidebar />
      <AbhayHeader /> */}
      <main className="vehicle-popup">
        <div className={`header-body  ${mainClass}`} id="cxMainB">
          <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <Link
                to="/VehiclesDetails"
                className="nav-link tab-link active"
                id="pills-goodsdetails-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-goodsdetails"
                type="button"
                role="tab"
                aria-controls="pills-goodsdetails"
                aria-selected="true"
              >
                Goods Details
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link
                to="/VehiclesDetails"
                className="nav-link tab-link "
                id="pills-driverdetails-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-driverdetails"
                type="button"
                role="tab"
                aria-controls="pills-driverdetails"
                aria-selected="true"
              >
                Vehicle Details
              </Link>
            </li>
          </ul>
          <div
            className="tab-content "
            id="pills-tabContent"
            style={{ marginBottom: "80px", overflow: "auto" }}
          >
            {/* Goods details */}
            <div
              className="tab-pane fade show active"
              id="pills-goodsdetails"
              role="tabpanel"
              aria-labelledby="pills-goodsdetails-tab"
            >
              {/* top  */}
              <div className="top-content common-body mt-4 d-flex justify-content-between">
                <div className="driver-profile-details d-flex align-items-center">
                  <div className="profile-pic me-3">
                    <img
                      src={
                        freightetails
                          ? ApiConfig.BASE_URL_FOR_IMAGES +
                            freightetails.trip_image
                          : user_1
                      }
                      alt=""
                      className="rounded-circle"
                    />
                  </div>
                  <div className="driver-name">
                    <div className="title-driver">
                      <h4 className="name-driver">
                        {freightetails && freightetails.trip_name}
                      </h4>
                    </div>
                    <div className="rating">
                      <img src={star_highlighted} alt="" />
                      <img src={star_highlighted} alt="" />

                      <img src={star_highlighted} alt="" />
                      <img src={star_highlighted} alt="" />
                      <img src={star_grey} alt="" />
                      <span>(320)</span>
                    </div>
                  </div>
                </div>
                <div className="icon-dropdown dropdown">
                  <a
                    href=""
                    className=""
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={ic_options_menu} alt="" height="24" />
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <button className="dropdown-item" type="button">
                        Edit{" "}
                        <img
                          src={dropdown_arrow_blue}
                          className="arrow"
                          alt=""
                        />
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        Change Vehicle
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        Block
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              {/* From details section */}
              <section className="from-details">
                <div className="common-body mt-4 p-3">
                  <div className="row">
                    <div className="top-content ">
                      <h5 className="card-heading">From</h5>
                      <hr />
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <label htmlFor="">Estimated Start Date</label>
                      <p className="data">
                        {freightetails && freightetails.trip_start_date}
                      </p>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                      <label htmlFor="">Estimated Start Time</label>
                      <p className="data">
                        {freightetails && freightetails.trip_start_time}
                      </p>
                    </div> */}
                    <div className="col-lg-3 col-md-6">
                      <label htmlFor="">Country</label>
                      <p className="data">
                        {freightetails && freightetails.trip_start_country}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label htmlFor="">City</label>
                      <p className="data">
                        {freightetails && freightetails.trip_start_city}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label htmlFor="">Address</label>
                      <p className="data">
                        {freightetails && freightetails.trip_start_address}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              {/* To details section */}
              <section className="to-details">
                <div className="common-body mt-4 p-3">
                  <div className="row">
                    <div className="top-content ">
                      <h5 className="card-heading">To</h5>
                      <hr />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label htmlFor="">Estimated End Date</label>
                      <p className="data">
                        {freightetails && freightetails.trip_end_date}
                      </p>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                      <label htmlFor="">Estimated End Time</label>
                      <p className="data">
                        {freightetails && freightetails.trip_end_time}
                      </p>
                    </div> */}
                    <div className="col-lg-3 col-md-6">
                      <label htmlFor="">Country</label>
                      <p className="data">
                        {freightetails && freightetails.trip_end_country}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label htmlFor="">City</label>
                      <p className="data">
                        {freightetails && freightetails.trip_end_city}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label htmlFor="">Address</label>
                      <p className="data">
                        {freight && freight.trip_end_address}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              {/* Goods details */}
              {/* <section className="goods-details mb-4"> */}
                {/* <div className="common-body mt-4 p-3"> */}
                  {/* <div className="row"> */}
                    {/* <div className="top-content ">
                      <h5 className="card-heading">Goods Details</h5>
                      <hr />
                    </div> */}
                    {/* left section */}
                    {/* <div className="col-lg-6">
                      <div className="image">
                        <div
                          id="carouselExampleControls"
                          className="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                    freightetails.trip_image || Skyscrapers
                                }
                                className="d-block "
                                alt={"..."}
                              />
                            </div>
                          </div>
                          <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleControls"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div> */}
                    {/* right section */}
                    {/* <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-4 mt-4">
                          <label htmlFor="">Company Name</label>
                          <p className="data">My Freight</p>
                        </div>
                        <div className="col-lg-4 mt-4">
                          <label htmlFor="">Cargo Type</label>
                          <p className="data">50m</p>
                        </div>
                        <div className="col-lg-4 mt-4">
                          <label htmlFor="">Quantity</label>
                          <p className="data">40to</p>
                        </div>
                        <div className="col-lg-4 mt-4">
                          <label htmlFor="">Expected Arrival Time</label>
                          <p className="data">Lorem ipsum dolo</p>
                        </div>
                      </div>
                    </div> */}
                  {/* </div> */}
                {/* </div> */}
              {/* </section> */}
            </div>
            {/* Vehicle details */}
            <div
              className="tab-pane fade show "
              id="pills-driverdetails"
              role="tabpanel"
              aria-labelledby="pills-driverdetails-tab"
            >
              {/* <div className="top-content common-body mt-4 d-flex justify-content-between">
                <div className="driver-profile-details d-flex align-items-center">
                  <div className="profile-pic me-3">
                    <img src={user_1} alt="" />
                  </div>
                  <div className="driver-name">
                    <div className="title-driver">
                      <h4 className="name-driver">Leroy Sane</h4>
                    </div>
                    <div className="rating">
                      <img src={star_highlighted} alt="" />
                      <img src={star_highlighted} alt="" />

                      <img src={star_highlighted} alt="" />
                      <img src={star_highlighted} alt="" />
                      <img src={star_grey} alt="" />
                      <span>(320)</span>
                    </div>
                  </div>
                </div>
                <div className="icon-dropdown dropdown">
                  <a
                    href=""
                    className=""
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={ic_options_menu} alt="" height="24" />
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <button className="dropdown-item" type="button">
                        Edit{" "}
                        <img
                          src={dropdown_arrow_blue}
                          className="arrow"
                          alt=""
                        />
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        Change Vehicle
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        Block
                      </button>
                    </li>
                  </ul>
                </div>
              </div> */}

              {/* Vehicle Lists */}

              <div
                className="contract-rates mt-4 mb-4"
                style={{ width: "100%", overflow: "auto" }}
              >
                <h4 className="card-heading">VEHICLE LIST</h4>
                <table className="table table-striped datalist">
                  <thead>
                    <tr>
                      <td>
                        <label htmlFor="">Sr.No.</label>
                      </td>
                      <td>
                        <label htmlFor="">Owner Name</label>
                      </td>
                      <td>
                        <label htmlFor="">Vehicle Type</label>
                      </td>
                      <td>
                        <label htmlFor="">Model</label>
                      </td>
                      <td>
                        <label htmlFor="">Load Capacity</label>
                      </td>
                      <td>
                        <label htmlFor="">Plate No.</label>
                      </td>
                      {/* <td>
                        <label htmlFor="">Driver Name</label>
                      </td>
                      <td>
                        <label htmlFor="">Nationality</label>
                      </td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleList && vehicleList.length > 0 ? (
                      vehicleList.map((vehicle, index) => {
                        return (
                          <tr className="Rowbgcolor " key={"vehicle" + index}>
                            <td>
                              <p className="data m-0">{index + 1}</p>
                            </td>
                            <td>
                              <p className="data m-0">
                                {vehicle.vehicle_owner}
                              </p>
                            </td>
                            <td>
                              <p className="data m-0">{vehicle.vehicle_type}</p>
                            </td>
                            <td>
                              <p className="data m-0">
                                {vehicle.vehicle_model_no}
                              </p>
                            </td>
                            <td>
                              <p className="data m-0">
                                {vehicle.vehicle_load_capacity}
                              </p>
                            </td>
                            <td>
                              <p className="data m-0">{vehicle.plate_number}</p>
                            </td>
                            {/* <td>
                              <p className="data m-0">{vehicle.driver_name}</p>
                            </td>
                            <td>
                              <p className="data m-0">{vehicle.nationality}</p>
                            </td> */}
                            <td>
                              <p className="data m-0">
                                <img
                                  src={eyes}
                                  alt=""
                                  height="24"
                                  onClick={() => handleShow()}
                                />
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <td>
                        <span className="text-danger">No data found</span>
                      </td>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Popup Box */}

              <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id=""
              >
                <Modal.Header closeButton>
                  <h4 className="card-heading">Vehicle Details</h4>
                </Modal.Header>
                <div>
                  <hr />
                  <section className="vehicle-detail-section mb-0 common-body p-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="image">
                          <div
                            id="carouselExampleControls"
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img
                                  src={Skyscrapers}
                                  className="d-block "
                                  alt={"..."}
                                />
                              </div>
                              <div className="carousel-item">
                                <img
                                  src={Skyscrapers}
                                  className="d-block "
                                  alt="..."
                                />
                              </div>
                              <div className="carousel-item">
                                <img
                                  src={Skyscrapers}
                                  className="d-block "
                                  alt="..."
                                />
                              </div>
                            </div>
                            <button
                              className="carousel-control-prev"
                              type="button"
                              data-bs-target="#carouselExampleControls"
                              data-bs-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                              className="carousel-control-next"
                              type="button"
                              data-bs-target="#carouselExampleControls"
                              data-bs-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Next</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="right-section">
                          <div className="right-top-content d-flex justify-content-between">
                            <div className="title-vehicle">
                              <h4 className="name-vehicle">
                                {vehicleInfo.vehicle_model_no}
                              </h4>
                            </div>
                          </div>
                          <div className="bottom-content row mt-4">
                            <div className="col-lg-4 left item">
                              <label>Plate Number</label>
                              <p className="data">{vehicleInfo.plate_number}</p>
                            </div>
                            <div className="col-lg-4 middel item">
                              <label>Vehicle Type</label>
                              <p className="data">{vehicleInfo.vehicle_type}</p>
                            </div>
                            {/* <div className=" col-lg-4 right item">
                              <label>Year of Manufacture</label>
                              <p className="data">{vehicleInfo.}</p>
                            </div> */}
                          </div>
                          <div className="bottom-content row mt-3">
                            <div className="col-lg-4 left item">
                              <label>Chassis Number</label>
                              <p className="data">
                                {vehicleInfo.vehicle_chassis_no}
                              </p>
                            </div>
                            <div className="col-lg-4 middel item">
                              <label>Motor Number</label>
                              <p className="data">
                                {vehicleInfo.vehicle_motor_no}
                              </p>
                            </div>
                            <div className="col-lg-4 right item">
                              <label>Gross Weight</label>
                              <p className="data">
                                {vehicleInfo.vehicle_gross_weight}
                              </p>
                            </div>
                          </div>
                          <div className="bottom-content row mt-3">
                            <div className="col-lg-4 left item">
                              <label>Vehicle Load Capacity</label>
                              <p className="data">
                                {vehicleInfo.vehicle_load_capacity}
                              </p>
                            </div>

                            <div className="col-lg-4 right item">
                              <label>Initial km</label>
                              <p className="data">
                                {vehicleInfo.vehicle_initial_km}
                              </p>
                            </div>
                          </div>
                          <h5 className="card-heading">GPS Availability</h5>
                          <div className="bottom-content row mt-3">
                            <div className="col-lg-4 left item">
                              <label>Vendor Name</label>
                              <p className="data">
                                {vehicleInfo.vehicle_vendor_name}
                              </p>
                            </div>

                            <div className="col-lg-4 right item">
                              <label>Vendor Contact</label>
                              <p className="data">
                                {vehicleInfo.vehicle_vendor_contact}
                              </p>
                            </div>
                            <div className="col-lg-4 right item">
                              <label>Vendor Platform</label>
                              <p className="data">
                                {vehicleInfo.vehicle_vendor_platform}
                              </p>
                            </div>
                            <div className="col-lg-4 right item">
                              <label>Vendor Address</label>
                              <p className="data">
                                {vehicleInfo.vehicle_vendor_address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </Modal>
            </div>
            {/* Driver Details */}
            <div
              className="tab-pane fade"
              id="pills-customerdetails"
              role="tabpanel"
              aria-labelledby="pills-customerdetails-tab"
            >
              <section className="owner-detail-section common-body p-3 mt-4 mb-4">
                <div className="row">
                  <div className="col-12">
                    {/* <div className="top-content">
                      <div className="driver-profile-details d-flex align-items-center">
                        <div className="profile-pic me-3">
                          <img src={user_1} alt="" />
                        </div>
                        <div className="driver-name">
                          <div className="title-driver">
                            <h4 className="name-driver">Leroy Sane</h4>
                          </div>
                          <div className="rating">
                            <img src={star_highlighted} alt="" />
                            <img src={star_highlighted} alt="" />

                            <img src={star_highlighted} alt="" />
                            <img src={star_highlighted} alt="" />
                            <img src={star_grey} alt="" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="row mt-4">
                      <div className="col-lg-2 col-md-6 ">
                        <label>Full Name</label>
                        <p className="data">Joshua Kimmich</p>
                      </div>
                      <div className="col-lg-2 col-md-6 ">
                        <label>Email</label>
                        <p className="data">Joshua@gmail.com</p>
                      </div>
                      <div className="col-lg-2 col-md-6 ">
                        <label>Phone Number</label>
                        <p className="data">+251 116 670336</p>
                      </div>
                      <div className="col-lg-2 col-md-6 ">
                        <label>Mobile Number</label>
                        <p className="data">(+251) 983858551</p>
                      </div>
                      <div className="col-lg-2 col-md-6 ">
                        <label>Zone</label>
                        <p className="data">City Name</p>
                      </div>
                      <div className="col-lg-2 col-md-6  ">
                        <label>Woreda</label>
                        <p className="data">Ethiopia</p>
                      </div>
                      <div className="col-lg-2 col-md-6  ">
                        <label>House No.</label>
                        <p className="data">344</p>
                      </div>
                      <div className="col-lg-2 col-md-6 ">
                        <label>P.O Box</label>
                        <p className="data">23</p>
                      </div>
                      <div className="col-lg-2 col-md-6  ">
                        <label>Birthdate</label>
                        <p className="data">22-Feb-2022</p>
                      </div>
                      <div className="col-lg-2 col-md-6 ">
                        <label>Gender</label>
                        <p className="data">Male</p>
                      </div>
                      {/* <div className="col-lg-6 col-md-6 mt-4 ">
                  <label>Address</label>
                  <p className="data">No.2/28H, Near Majid, Sainiketan Colony, Kalas Area</p>
                </div> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ViewDetails;
