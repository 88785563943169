import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import eyes from "../../assets/images/eyes.svg";
import "../../assets/css/Driverlist.scss";
import ic_import from "../../assets/images/Trasnvehicle/ic_import.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import ProgressBar from "../../sharedComponent/ProgressBar";
import swal from "sweetalert";
import * as XLSX from "xlsx";
const DriverList = () => {
  const [driverList, setDriverList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { mainClass, customerData } = useContext(AppContext);

  const exportToexcel = () => {
    var excelData = [
      [
        "S.No",
        "Driver Name",
        "Nationality",
        "Email id",
        "Mobile Number",
        "License Number",
      ],
    ];
    driverList.map((elt, index) => {
      excelData.push([
        index + 1,
        elt.driver_name,
        elt.nationality,
        elt.email_id,
        elt.mobile_number,
        elt.licence_number,
      ]);
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "Drivers_list.xlsx");
  };
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".datalist")) {
      table = $("#datalist").DataTable();
    } else {
      table = $(".datalist").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });
  useEffect(() => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DRIVER_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setDriverList(res.json.driver_list);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const blockDriver = (driver) => {
    setProgress(25);
    setProgress(50);
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_DRIVER,
      JSON.stringify({ ...customerData, driver_id: driver.driver_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setProgress(100);
        if (res.json.result) {
          swal(res.json.message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <React.Fragment>
      <ProgressBar progress={progress} />

      <div className={"vehicle " + mainClass} id="cxMain">
        {loading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className=" ">
              <div className="d-flex  ">
                <div className="input-group  justify-content-between ">
                  <div className="icon-group col-sm-3  mt-2">
                    <ul className="d-flex list-unstyled  justify-content-end ">
                      <li className="nav-item icons p-2 me-3">
                        <Link to="#" onClick={exportToexcel}>
                          <img
                            src={ic_import}
                            className="add"
                            alt="send-load-icon"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="">
                <div className="" style={{ width: "100%", overflow: "auto" }}>
                  <table className="datalist" id="example">
                    <thead>
                      <tr>
                        <th>
                          {" "}
                          <label>S.No</label>
                        </th>
                        <th>
                          {" "}
                          <label>Driver Name</label>
                        </th>
                        <th>
                          {" "}
                          <label>Nationality</label>
                        </th>
                        <th>
                          {" "}
                          <label>Email id</label>
                        </th>
                        <th>
                          {" "}
                          <label>Mobile Number</label>
                        </th>
                        <th>
                          {" "}
                          <label>License Number</label>
                        </th>
                        <th>
                          {" "}
                          <label>Driver Status</label>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {driverList &&
                        driverList.length &&
                        driverList.map((driver, index) => {
                          return (
                            <tr
                              className="Rowbgcolor p-2"
                              key={"driver" + index}
                            >
                              <td>{driver.driver_id}</td>
                              <td id="DriverNAM">{driver.driver_name}</td>
                              <td id="Nationality">{driver.nationality}</td>
                              <td id="emial">{driver.email_id}</td>
                              <td id="mobileNO">{driver.mobile_number}</td>
                              <td id="LicenseNo">{driver.licence_number}</td>
                              <td id="LicenseNo">{driver.status}</td>
                              <td>
                                <div className="option-dropdown dropdown p-2">
                                  <Link
                                    to="/DriverDetails"
                                    className="text-decoration-none"
                                    state={driver}
                                  >
                                    <button
                                      className="dropdown-item "
                                      type="button"
                                    >
                                      <img src={eyes} alt="" />
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DriverList;
