import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import AppState from './context/user/AppState';

ReactDOM.render(
  <BrowserRouter>
    <AppState>

      <App />
    </AppState>

  </BrowserRouter>,
  document.getElementById('root')
);
