import {
  Link,
  NavLink,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Abay_logo from "../assets/images/header/Abay_logo.png";
import ic_notification from "../assets/images/header/ic_notification.svg";
import ic_options from "../assets/images/header/ic_options.svg";
import profile_img from "../assets/images/header/profile_img.png";
import user_2 from "../assets/images/header/ChangeDriver/user_2.svg";
import ic_contact_us from "../assets/images/header/Profile/ic_contact_us.svg";
import right_arrow from "../assets/images/header/Profile/right_arrow.svg";
import ic_report from "../assets/images/header/Profile/ic_report.svg";
import ic_settings from "../assets/images/header/Profile/ic_settings.svg";
import Privacy from "../assets/images/header/Profile/Privacy.svg";
import ic_legal from "../assets/images/header/Profile/ic_legal.svg";
import ic_logout from "../assets/images/header/Profile/ic_logout.svg";
import dropdown_arrow_blue from "../assets/images/dropdown_arrow_blue.svg";
import ic_profile from "../assets/images/line_user-3.svg";

import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import moment from "moment";
import ApiConfig from "../api/ApiConfig";
import { AppContext } from "../context/user/AppContext";
import swal from "sweetalert";
import dropdown from "../assets/images/sidebar/dropdown.svg";
import arrow_highlight from "../assets/images/sidebar/arrow_highlight.svg";
import Loader from "./BarLoader";
const AbhayHeader = ({ setLoggedIn }) => {
  const location = useLocation();

  let currentRoute = location.pathname;

  const [drop, setDrop] = useState(false);

  const {
    customerData,
    profile,
    apiCalling,
    setProfile,
    notificationList,
    setNotificationList,
  } = useContext(AppContext);
  useEffect(() => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.PROFILE,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
        if (res.json.result) {
          
          setProfile(res.json.profile);
        }
      })
      .catch((err) => console.log(err));

    postWithAuthCallWithErrorResponse(
      ApiConfig.NOTIFICATION,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setNotificationList(res.json.notifications);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const deleteNotification = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELETE_NOTIFICATION,
      JSON.stringify({ ...customerData, notification_ids: [id] })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      swal(res.json.message);
    });
  };

  const mobileSidebar = () => {};
  // const handleLogout = () => {
  //   localStorage.clear();
  //   setLoggedIn(false);
  //   // navigate('/login')
  // };
  const handleLogout = () => {
    
    postWithAuthCallWithErrorResponse(
      ApiConfig.TRANSPORTER_LOGOUT_API,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
       

        localStorage.clear();
        setLoggedIn(false);
        swal(res.json.message);   
      }else {
        swal("Error While Logging Out"); 
      }
      
    }).catch(e=>{
      swal("Error Occured While Logging Out!"); 
    });
  };
  return (
    <React.Fragment>
      <header className="ab-header">
        <nav className="navbar navbar-expand-md cx-navbar">
          <div className="container-fluid ab-header-container">
            <Link to="/Dashboard" className="navbar-logo">
              <img
                src={Abay_logo}
                className="img-logo"
                alt="Abay-logo"
                onClick={mobileSidebar}
              />
            </Link>
            <div className="dash">Dashboard</div>
            <ul className="navbar-nav ms-auto ">
              <li className="nav-item ">
                <a
                  className="nav-link pe-auto"
                  aria-current="page"
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightNotification"
                  aria-controls="offcanvasRightNotification"
                >
                  <img
                    // onClick={handleNotif}
                    src={ic_notification}
                    alt="notification-icon"
                  />
                  <div className="cx-alert">
                    {notificationList.length && notificationList.length}
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link pe-auto"
                  aria-current="page"
                  // onClick={handleClick}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightAccount"
                  aria-controls="offcanvasRightAccount"
                >
                  <img
                    src={
                      ApiConfig.BASE_URL_FOR_IMAGES +
                        profile.basic_info.profile_pic || profile_img
                    }
                    alt="Profileimg"
                    width="32"
                    height="32"
                    className="rounded-circle"
                  />
                </a>
              </li>
            </ul>
          </div>
        </nav>
        {apiCalling && <Loader loading={apiCalling} />}
      </header>

      {/* //##-------------------------##
              Change Driver Start
         //##-------------------------## */}

      {/* ---------------------------------------------------------
                                Notification 
      ----------------------------------------------------------- */}

      <section>
        <div className="notification-out">
          <div
            className="Notification-content offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasRightNotification"
            aria-labelledby="offcanvasRightLabelNotification"
          >
            <div className="offcanvas-header justify-content-end">
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div className=" Notification-header">
              <div className="d-flex  justify-content-between hr-line pb-2 mb-3">
                <p className="card-heading">Notifications</p>
                <p
                  className="me-2 text-decoration-none"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link to="/Notifications" className="see">
                    See All
                  </Link>
                </p>
              </div>

              <div className="inside mt-2 mb-4">
                {notificationList.map((notification, index) => {
                  return (
                    <div key={"notification" + index}>
                      <div className="d-flex justify-content-between hr-line pb-2 mb-3">
                        <div className=" ">
                          <p className="data-notification">
                            {notification.notification_text}.{" "}
                          </p>
                          <label>
                            {moment(
                              new Date(
                                notification.notification_date +
                                  " " +
                                  notification.notification_time
                              )
                            ).fromNow()}
                          </label>
                        </div>
                        <div className="option-dropdown dropdown ">
                          <a
                            href=""
                            className=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={ic_options} alt="" className="ms-1" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() =>
                                  deleteNotification(
                                    notification.notification_id
                                  )
                                }
                              >
                                Delete{" "}
                                <img
                                  src={dropdown_arrow_blue}
                                  className="orderConArow"
                                  alt=""
                                />
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ----------------------------------------------------------------
                          profile
      ---------------------------------------------------------------
       */}

      <section className="">
        <div className="cx-account-out">
          <div className=" Account-content">
            <div className="Account-header">
              <div
                className={
                  drop
                    ? "Account offcanvas offcanvas-end show"
                    : "Account offcanvas offcanvas-end"
                }
                tabIndex="-1"
                id="offcanvasRightAccount"
                aria-labelledby="offcanvasRightLabel"
                role="dialogue"
              >
                <div className="offcanvas-header justify-content-end">
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  className="insidepro text-center border-bottom "
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link to="/profile">
                    <img
                      src={
                        ApiConfig.BASE_URL_FOR_IMAGES +
                          profile.basic_info.profile_pic || user_2
                      }
                      alt="responsive-image"
                      className="rounded-circle"
                    />
                  </Link>

                  <p className="Header-profile-name mt-2" htmlFor="">
                    {profile.basic_info.company_name}
                  </p>

                  <label className="mb-4">{profile.address.email}</label>
                </div>

                <ul className="list-container account-list-style">
                  <li
                    className="list-unstyled mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="/profile" className="icon_arrow ">
                      <div className="d-flex  promenu">
                        <img src={ic_profile} alt="" />
                        <h5 className="contnt  m-2">Profile</h5>
                      </div>
                      <img className="" src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li
                    className="list-unstyled mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="/ContactUs" className="icon_arrow border-bottom">
                      <div className="d-flex promenu">
                        <img src={ic_contact_us} alt="" />
                        <h5 className="contnt  m-2">Contact Us </h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li className="list-unstyled border-bottom mt-2">
                    <Link
                      to="#"
                      onClick={() => setDrop(!drop)}
                      className={
                        `icon_arrow ` +
                        (currentRoute === "/ChangePassword" && "parentActive")
                      }
                    >
                      <div className="d-flex promenu">
                        <img className="" src={ic_settings} alt="" />
                        <h5 className="contnt  m-2">Settings</h5>
                      </div>
                      {drop ? (
                        <img
                          className="Arrow_hidde  "
                          src={dropdown}
                          id="icon_Show"
                        />
                      ) : (
                        <img
                          className="Arrow_hidde "
                          src={right_arrow}
                          id="icon_Show"
                        />
                      )}
                    </Link>
                  </li>
                  {drop && (
                    <div>
                      <div id="example-collapse-text">
                        <ul style={{ paddingLeft: "0px" }}>
                          <li
                            className="good list-unstyled"
                            onClick={() => setDrop(!drop)}
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            <Link
                              // style={({ isActive }) => {
                              //   return { opacity: isActive ? "1" : "0.5" };
                              // }}

                              to="/ChangePassword"
                              className="MGT text-decoration-none text-dark"
                            >
                              {" "}
                              <img
                                src={arrow_highlight}
                                className="Arrow_hidde"
                                id="icon_Show"
                                alt="dropdown"
                              />
                              Change Password
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  <li
                    className="list-unstyled border-bottom mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="/TermsAndConditions" className="icon_arrow">
                      <div className="d-flex promenu">
                        <img className="" src={ic_legal} alt="" />
                        <h5 className="contnt  m-2">Terms & Conditions</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li
                    className="list-unstyled border-bottom mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="/Dashboard" className="icon_arrow">
                      <div className="d-flex promenu">
                        <img className="" src={Privacy} alt="" />
                        <h5 className="contnt  m-2">Privacy Policy</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li
                    className="list-unstyled border-bottom mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <a onClick={() => handleLogout()} className="icon_arrow">
                      <div className="d-flex promenu">
                        <img className="" src={ic_logout} alt="" />
                        <h5 className="contnt  m-2">Logout</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AbhayHeader;
