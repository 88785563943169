import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom"
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import remove from "../../assets/images/ic_remove_red.svg";
import { Modal, Button } from "react-bootstrap";
import { postMultipartWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
const   OfferedVehicles = () => {
  const currentRoute = useLocation().pathname;
  $(document).ready(function () {
    let table
    if ($.fn.dataTable.isDataTable('.dataList')) {
      table = $('.dataList').DataTable();
    }
    else {
      table = $('.dataList').DataTable({
        ordering: false,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
  });
  const [show, setShow] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const { customerData, mainClass } = useContext(AppContext)
  const [offerlist, setOfferList] = useState([])
  const [loading, setLoading] = useState(false)
  const [vehicleList, setVehicleList] = useState([])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentOffer, setCurrentOffer] = useState({})
  const handleShowAccept = () => setShowAccept(true);
  const handleCloseAccept = () => setShowAccept(false);
  useEffect(() => {
    setLoading(true)
    postMultipartWithAuthCallWithErrorResponse(currentRoute === "/OfferedVehicles" ? ApiConfig.DIRECT_ORDERS_OFFERED_VEHICLES : ApiConfig.DIRECT_ORDERS_OFFERED_VEHICLES_ONLINE, JSON.stringify({ ...customerData }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false)
        setOfferList(res.json.offer_list);
      }).catch(err => {
        console.log(err);
      })
  }, [])
  // useEffect(() => {
  //   console.log("currentOffer", currentOffer);
  //   postMultipartWithAuthCallWithErrorResponse(ApiConfig.DRIRECT_ORDER_OFFER_VEHICLES_VEHICLE_DETAILS, JSON.stringify({ ...customerData, load_id: currentOffer.trip_id }))
  //     .then(res => {
  //       if (res.json.message === 
  //         "Invalid user authentication,Please try to relogin with exact credentials.") {
  //           localStorage.clear();
  //           window.open("/login", "_self");
  //       }
  //       setVehicleList(res.json.vehicle_list)
  //     }).catch(err => {
  //       console.log(err);
  //     })
  // }, [currentOffer])
  return (

    <main className={"vehicle-request-main " + mainClass} id="cxMain">
      <section className="table-vehicle-request" style={{ overflow: "auto" }}>
        {loading ?
          <Loader loading={loading} />
          :
          <table className=" datalist" style={{ minWidth: "1400px" }}>
            <thead>
              <tr>
                <td>
                  <label htmlFor="">S.No.</label>
                </td>
                <td>
                  <label htmlFor="">Offer Load Reference Number</label>
                </td>
                <td>
                  <label htmlFor="">Vehicle Availability Dt.</label>
                </td>
                <td>
                  <label htmlFor="">From & To</label>
                </td>
                <td>
                  <label htmlFor="">Cargo Type</label>
                </td>
                <td>
                  <label htmlFor="">Container Type</label>
                </td>
                <td>
                  <label htmlFor="">Remaining Qty.</label>
                </td>
                <td>
                  <label htmlFor="">Vehicles Offered</label>
                </td>
                {/* <td>
                  <label htmlFor="">License No</label>
                </td>
                <td>
                  <label htmlFor="">Driver Id</label>
                </td>
                <td>
                  <label htmlFor="">Status</label>
                </td> */}
              </tr>
            </thead>
            <tbody>

              {offerlist && offerlist.length && offerlist.map((offer, index) => {
                return (
                  <tr key={"offer" + index} className="t-row ">
                    <td>
                      <p className="data m-0 ps-2">{index + 1}</p>
                    </td>
                    <td>
                      <Link to="/GoodsDetails" state={offer} className="ms-2 ViewSpecifications-link">
                        {offer.load_reference_no}
                      </Link>
                    </td>
                    <td>
                      <p className="data m-0"> {offer.estimated_start_date}</p>
                    </td>
                    <td>
                      <p className="data m-0">{offer.trip_start_country + " " + offer.trip_start_city + " - " + offer.trip_end_country + " " + offer.trip_end_city}</p>
                    </td>
                    <td>
                      <p className="data m-0">{offer.cargo_type}</p>
                    </td>
                    <td>
                        {offer.cargo_type == "Container" ? (
                          <p className="data m-0">
                            {offer.container_type}
                          </p>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                      {offer.cargo_type == "Container" ? (
                        <p className="data m-0">{offer.trip_pending_quantity === "" ? offer.quantity : offer.trip_pending_quantity} Quintals</p>
                      ):offer.cargo_type == "Vehicle" ? (
                        <p className="data m-0">{offer.trip_pending_quantity === "" ? offer.quantity : offer.trip_pending_quantity} Vehicles</p>
                      ):(<p className="data m-0">{offer.trip_pending_quantity === "" ? offer.quantity : offer.trip_pending_quantity} Quintals</p>)}
                      </td>
                    <td>
                      <Link
                        // onClick={() => {
                        //   setCurrentOffer(offer)
                        //   setVehicleList([])
                        //   handleShow()
                        // }}
                        state={offer}
                        to={"/ViewOrderOfferedVehicles"}
                        className="ms-2 ViewSpecifications-link"
                      >
                        View Details
                      </Link>
                    </td>
                    {/* <td>
                      <p className="data m-0">{offer.vehicle_number}</p>
                    </td>
                    <td>
                      <p className="data m-0">{offer.driver_id}</p>
                    </td>
                    <td>
                      <p className="data m-0">{offer.trip_vehicle_status}</p>
                    </td> */}
                  </tr>
                )

              })}


            </tbody>
          </table>
        }

      </section>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box"
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <h3 className=" card-heading mt-4">Truck Specifications </h3>
        </div>
        <div
          className="btn-popup-modal text-center"
          style={{ overflow: "auto" }}
        >
          <table className="example table-striped" style={{ minWidth: "auto" }}>
            <thead>
              <tr>
                <td>
                  <label htmlFor="">Trucks</label>
                </td>
                <td>
                  <label htmlFor="">Vehicle Type</label>
                </td>
                <td>
                  <label htmlFor="">Capacity in Quintals</label>
                </td>
              </tr>
            </thead>
            <tbody>
              {vehicleList && vehicleList.map((vehicle, index) => {
                return (
                  <tr className="t-row " key={index + "vehcleDetails"}>
                    <td>
                      <p className="data m-0 ps-2">{index + 1}</p>
                    </td>
                    <td>
                      <p className="data m-0">{vehicle.vehicle_type}</p>
                    </td>
                    <td>
                      <p className="data m-0">{vehicle.vehicle_load_capacity}</p>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal
        className="modal-md accept-popup"
        id="myModal"
        role="dialog"
        onHide={handleCloseAccept}
        animation={true}
        show={showAccept}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <div>
          <p className="text-center popup-heading mt-4">
            Are you sure you want to approve this offer?
          </p>
        </div>
        <div className="btn-popup-modal justify-content-evenly  d-flex">
          <Link to="#" className="m-1">
            <button type="button" className="popup-reject-btn ">
              Reject
            </button>
          </Link>

          <Link to="#" className="m-1" onClick={handleShow}>
            <button type="button" className="popup-approve-btn ">
              Approve
            </button>
          </Link>
        </div>
      </Modal>
    </main>

  )
}

export default OfferedVehicles