import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import { AppContext } from "../../context/user/AppContext";

const TransporterRateExport = () => {
  const { mainClassB, customerData } = useContext(AppContext);
  const [tariffExprotList, setTariffExportList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    TarrifExportList();
  }, []);
  const TarrifExportList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TARRIF_EXPORT_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      console.log(res);
      setTariffExportList(res.json.tariff_export_list);
      setLoading(false);
    });
  };
  return (
    <main className={`contract-main-section ${mainClassB}`} id="cxMainB">
      <div className="rates-main " style={{ margin: "0 20px" }}>
        <div className="transporter">
          <div className="heading-top">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Search"
            />
          </div>
          <div className="offerLoadsMainLists">
            <div className="mainLoadTable" style={{ overflow: "auto" }}>
              <table className="dataList" style={{ minWidth: "900px" }}>
                <thead>
                  <tr>
                    <th>
                      <label>S.No.</label>
                    </th>
                    <th>
                      <label>Destination</label>
                    </th>
                    <th>
                      <label>Km</label>
                    </th>
                    <th>
                      <label>Currency</label>
                    </th>

                    <th>
                      <label>Unit of Measurement</label>
                    </th>
                    <th className="">
                      <label>Tariff (Birr) </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tariffExprotList &&
                    tariffExprotList.map((tariffExprotListData, index) => {
                      return (
                        <tr
                          className="Rowbgcolor"
                          key={"tariffExprotListData" + index}
                        >
                          <td>
                            <label>{index + 1}</label>
                          </td>
                          <td>
                            <label>
                              {tariffExprotListData.tariff_export_destination}
                            </label>
                          </td>
                          <td>
                            <label>
                              {tariffExprotListData.tariff_export_km}
                            </label>
                          </td>
                          <td>
                            <label>{tariffExprotListData.currency_code}</label>
                          </td>

                          <td>
                            <label>{tariffExprotListData.unit_name}</label>
                          </td>

                          <td>
                            <label>
                              {tariffExprotListData.tariff_export_tariff}
                            </label>
                          </td>
                          <td>
                            <label></label>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TransporterRateExport;
